import { FC } from 'react';
import { useDrag, DragPreviewImage } from 'react-dnd';
import { ItemTypes } from './ItemTypes';
import React from 'react';
import { desktopBreakpoint, desktopVW, mobileVW } from '../../styles';
import styled from 'styled-components';

export interface BoxProps {
  id: string;
  left: number;
  top: number;
  width: number;
  height: number;
  hideSourceOnDrag: boolean;
  image: string;
  children: React.ReactNode;
  onDelete: Function;
  dragging: boolean;
  isMobile: boolean;
  scale: any;
}

const itemType = ItemTypes.BOX;

export const BoxItem: FC<BoxProps> = ({
  id,
  left,
  top,
  width,
  height,
  children,
  onDelete,
  isMobile,
}) => {
  const [{ isDragging }, drag, preview] = useDrag({
    type: itemType,
    item: { id, left, top, width, height },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? (isMobile ? 1 : 1) : 1;

  return (
    <div>
      <DragPreviewImage connect={preview} src="https://placehold.jp/0/262525/ffffff/1x1.png" />
      <div
        ref={drag}
        className={isDragging ? 'dragging' : ''}
        style={{
          position: 'absolute',
          left,
          top,
          width: width,
          height: height,
          opacity,
          cursor: 'move',
          backgroundColor: 'transparent',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '15px',
          transition: 'none',
        }}
      >
        <div
          className="box-container box-container-items-shelfmate"
          style={{ position: 'relative', width: '100%', height: '100%' }}
        >
          <BoxStyledImgClose className="component-shelfmate-remove" onClick={() => onDelete(id)}>
            <StyledImgClose
              src={
                'https://images.ctfassets.net/hhqcfrz88uh8/6I5KELYmBurzEGGoHVuqdW/f263e49aeb2a51439462fcb5f3ec186f/icon_close.svg'
              }
              alt="arrow close"
              width="100"
              height="100"
            />
          </BoxStyledImgClose>

          {children}
        </div>
      </div>
    </div>
  );
};

const BoxStyledImgClose = styled.div`
  background: white;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: ${mobileVW(24)};
  height: ${mobileVW(24)};
  top: -${mobileVW(6)};
  right: -${mobileVW(6)};
  border-radius: ${mobileVW(12)};

  ${desktopBreakpoint} {
    width: ${desktopVW(20)};
    height: ${desktopVW(20)};
    top: -${desktopVW(6)};
    right: -${desktopVW(6)};
    border-radius: ${desktopVW(10)};
  }
`;

const StyledImgClose = styled.img`
  width: ${mobileVW(8)};
  height: ${mobileVW(8)};
  ${desktopBreakpoint} {
    width: ${desktopVW(7)};
    height: ${desktopVW(7)};
  }
`;
