/* eslint-disable */
import Img from 'gatsby-image';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors, desktopBreakpoint, desktopVW, mobileVW, zIndex } from '../../styles/index';

interface Props {
  mediaSection: {
    video: any;
    videoVimeo: string;
    videoTitle: string;
    image: string;
    title: string;
    videoFromYoutube: string;
  };
}

export default function CollectionContentBeforeProduct(props: Props) {
  const { mediaSection } = props;
  const [idYt, setIdYt] = useState('');
  const [videoTime, setVideoTime] = useState(0);
  const videoRef = useRef();

  useEffect(() => {
    if (mediaSection.videoFromYoutube) {
      let getId = mediaSection.videoFromYoutube?.split('watch?v=');
      setIdYt(getId[1]);
    }
  }, [mediaSection.videoFromYoutube]);

  const ContentMedia = () => {
    if (mediaSection.image) {
      return <StyledImage fluid={mediaSection.image.fluid} />;
    } else if (idYt != '') {
      return (
        <VideoPlayerYoutube
          style={{ border: 0 }}
          src={`https://www.youtube.com/embed/${idYt}?autoplay=1&loop=1&rel=0&mute=1&controls=0&showinfo=0&color=white&playlist=${idYt}&vq=hd1080`}
        />
      );
    } else if (mediaSection.videoVimeo || mediaSection.video?.file?.url) {
      return (
        <VideoPlayer
          controls={false}
          autoPlay
          disableRemotePlayback
          loop
          playsInline
          muted
          preload="meta"
          ref={videoRef}
          onTimeUpdate={e => setVideoTime(Math.round(videoRef.current.currentTime))}
        >
          <source
            src={`${
              mediaSection.videoVimeo ? mediaSection.videoVimeo : mediaSection.video?.file?.url
            }`}
            type="video/mp4"
          />
        </VideoPlayer>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Wrapper>
      <RelativeWrapper>
        <MediaCropper>
          <ContentMedia />
        </MediaCropper>
        <TextBlock>
          <p className="thumbnailTitle">{mediaSection.title}</p>
        </TextBlock>
        <div>{}</div>
      </RelativeWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${mobileVW(0)} ${mobileVW(0)} ${mobileVW(50)} ${mobileVW(0)};

  ${desktopBreakpoint} {
    flex-direction: row;
    padding: ${desktopVW(0)} ${desktopVW(0)} ${desktopVW(20)} ${desktopVW(0)};
  }
`;

const VideoPlayerYoutube = styled.iframe`
  object-fit: cover;
  position: relative;
  top: 0;
  left: 0;
  width: ${mobileVW(900)};
  height: ${mobileVW(280)};
  margin-left: -${mobileVW(255)};
  pointer-events: none;
  z-index: ${zIndex.background};

  &::-webkit-media-controls {
    display: block;
    -webkit-appearance: block;
  }

  ${desktopBreakpoint} {
    width: ${desktopVW(850)};
    height: ${desktopVW(450)};
    margin-left: -${desktopVW(100)};
  }
`;

const TextBlock = styled.div`
  width: 100%;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  padding-top: ${mobileVW(5)};
  padding-bottom: 0;
  bottom: 0;
  left: 0;

  .priceRow {
    display: flex;
    flex-direction: row;
  }

  p {
    color: ${colors.black};
    line-height: 150%;
    font-size: ${mobileVW(15)};
    font-family: 'messinaMonoLight', 'Courier New', Courier, monospace;
    letter-spacing: -0.05em;
    ${desktopBreakpoint} {
      font-size: ${desktopVW(14)};
      padding-top: 0;
    }
  }

  ${desktopBreakpoint} {
    padding-top: ${desktopVW(10)};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 0;
    height: auto;
  }
`;

const VideoPlayer = styled.video`
  object-fit: cover;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: ${mobileVW(280)};
  z-index: ${zIndex.background};
  &::-webkit-media-controls {
    display: block;
    -webkit-appearance: block;
  }

  ${desktopBreakpoint} {
    //position: absolute;
    height: 100%;
    width: 100%;
    left: auto;
    top: 0;
    right: 0;
  }
`;

const MediaCropper = styled.div`
  height: ${mobileVW(280)};
  width: 100%;
  overflow: hidden;
  position: relative;

  ${desktopBreakpoint} {
    width: 45vw;
    height: ${desktopVW(450)};
  }
`;

const StyledImage = styled(Img)`
  width: 100%;
  z-index: ${zIndex.background};
  height: 100%;
`;

const VideocontrolWrapper = styled.div`
  position: absolute;
  left: ${mobileVW(5)};
  bottom: ${mobileVW(10)};
  z-index: ${zIndex.surface};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${desktopBreakpoint} {
    left: ${desktopVW(50)};
    bottom: ${desktopVW(30)};
  }
`;

const PlayButton = styled.button`
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${mobileVW(60)};
  height: ${mobileVW(60)};

  ${desktopBreakpoint} {
    width: ${desktopVW(60)};
    height: ${desktopVW(60)};
  }
`;

const VideoText = styled.div`
  font-family: 'messinaMono';
  letter-spacing: -0.05em;
  font-size: ${mobileVW(16)};
  color: ${colors.white};

  ${desktopBreakpoint} {
    font-size: ${desktopVW(13)};
  }
`;

const VideoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  padding-left: ${mobileVW(20)};
  ${desktopBreakpoint} {
    padding-left: ${desktopVW(20)};
  }
`;

const RelativeWrapper = styled.div`
  position: relative;
  width: 100%;

  ${desktopBreakpoint} {
    width: 50%;
  }
`;
