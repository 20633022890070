// @ts-nocheck
import React, { useCallback, useEffect, useState, useRef } from 'react';
import Playground from './Playground';
import Sidebar from './Sidebar';
import styled from 'styled-components';
import { desktopBreakpoint, desktopVW } from '../../styles';
import paper from '../../../static/paperBackground/paper.jpeg';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useGlobalDictionaryQuery } from '../../hooks';
import { TouchBackend } from 'react-dnd-touch-backend';
import HeaderMobile from './Mobile/headerMobile';
import IntroJs from 'intro.js';
import 'intro.js/minified/introjs.min.css';

type Props = {
  product: any;
};

export default function ShelfMateApp({ product }: Props) {
  const dictionary = useGlobalDictionaryQuery();
  const [hideSourceOnDrag, setHideSourceOnDrag] = useState(true);
  const [newitemAdded, setNewItemAdded] = useState([]);
  const [positionNewItem, setPositionNewItem] = useState({ left: 0, top: 0 });
  const [amount, setAmount] = useState({ price: 0, compareAtPrice: 0, items: [] });
  const [template, setTemplate] = useState([]);
  const [generateTemplate, setGenerateTemplate] = useState([]);
  const [addTemplate, setAddTemplate] = useState(null);
  const [isMobile, setIsMobile] = useState(
    typeof window != 'undefined' && window.innerWidth <= 1024 ? true : false
  );
  const [tutorialShow, setTutorialShow] = useState(false);
  const [scale, setScale] = useState(1);
  const [startDistance, setStartDistance] = useState(0);
  const containerRef = useRef(null);
  const minScale = 0.5;
  const maxScale = 1.2;
  const [tutorial, setTutorial] = useState(false);
  const getDistance = (touch1, touch2) => {
    const dx = touch2.clientX - touch1.clientX;
    const dy = touch2.clientY - touch1.clientY;
    return Math.sqrt(dx * dx + dy * dy);
  };
  let tutorialShelfmate = localStorage.getItem('tutorial-shelfmate-app');
  const handleTouchStart = e => {
    if (e.touches.length === 2) {
      const distance = getDistance(e.touches[0], e.touches[1]);
      setStartDistance(distance);
    }
  };

  const handleTouchMove = e => {
    if (e.touches.length === 2) {
      e.preventDefault();

      const currentDistance = getDistance(e.touches[0], e.touches[1]);
      const diff = currentDistance - startDistance;

      if (diff !== 0) {
        setScale(prevScale => {
          const newScale = prevScale + diff / 100;
          return Math.min(Math.max(newScale, minScale), maxScale);
        });
        setStartDistance(currentDistance);
      }
    }
  };

  const handleTouchEnd = () => {
    setStartDistance(0);
  };

  useEffect(() => {
    const isClient = typeof window === 'object';
    if (!isClient) return false;
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        setIsMobile(true);
        return;
      }
      setIsMobile(false);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function combineData(array) {
    const result = {};
    array.forEach(item => {
      if (item.shelfmateGroup === null) {
        return;
      }
      const key = `${item.shelfmateGroup}`;
      if (!result[key]) {
        result[key] = {
          type: item.shelfmateGroup,
          title: item.titleToShow,
          data: [],
        };
      }
      result[key].data.push(item);
    });
    return Object.values(result).map(entry => ({
      type: entry.type,
      title: entry.title,
      data: entry.data,
    }));
  }

  const combineDataResult = combineData(product).sort((a, b) => a.title.localeCompare(b.title));

  const newItem = (e: any) => {
    if (e != null) {
      setNewItemAdded(e);
    }
  };

  async function fetchJsonData(url) {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  }

  const dispayTutorial = () => {
    setTutorialShow(true);
    setTimeout(() => {
      const intro = IntroJs();
      intro.onstart(() => {
        setTutorial(true);
      });

      intro.oncomplete(() => {
        setTutorial(false);
        setTutorialShow(false);
      });
      intro.setOptions({
        steps: [
          {
            element: isMobile ? '#step-mobile-1' : '#step-desktop-1',
            intro:
              '<b>Drag n Drop Products</b><p>To start, you can drag n drop the products to the Playground</p>',
            position: isMobile ? 'bottom' : 'left',
            tooltipClass: 'myTooltipClass',
            highlightClass: 'myHighlightClass',
          },
          {
            element: isMobile ? '#step-mobile-2' : '#step-desktop-2',
            intro:
              '<b>Setup Template</b><p>Besides that, you can also using setup template to start layout the Shelfmate</p>',
            position: isMobile ? 'bottom' : 'left',
          },
        ],
        showStepNumbers: true,
        exitOnOverlayClick: false,
        nextLabel:
          'Next <img src="https://images.ctfassets.net/hhqcfrz88uh8/D3dBHm7b2HSUc7wrw46ir/07adb09c629899282947983b2b0b85f9/arrow-right__1_.svg" alt="arrow" />',
        prevLabel: 'Back',
        skipLabel: 'Skip',
        doneLabel:
          'Finish <img src="https://images.ctfassets.net/hhqcfrz88uh8/D3dBHm7b2HSUc7wrw46ir/07adb09c629899282947983b2b0b85f9/arrow-right__1_.svg" alt="arrow" />',
      });

      intro.start();
      localStorage.setItem('tutorial-shelfmate-app', true);
    }, 1000);
  };

  useEffect(() => {
    if (template.length === 0) {
      let url = [];
      if (isMobile) {
        dictionary.templateShelfmateAppMobile.map((items: any) => {
          url.push(items.file.url);
        });
      } else {
        dictionary.templateShelfmateApp.map((items: any) => {
          url.push(items.file.url);
        });
      }
      url.forEach(url => {
        fetchJsonData(url)
          .then(data => setTemplate(prevItems => [...prevItems, data]))
          .catch(error => console.error('Error fetching JSON:', error));
      });
    }
  }, [dictionary.templateShelfmateApp, dictionary.templateShelfmateAppMobile]);

  useEffect(() => {
    if (template.length > 0) {
      const groupedData = Object.values(
        template.reduce((acc, item) => {
          const category = item.category;

          if (!acc[category]) {
            acc[category] = {
              type: category,
              data: [],
            };
          }

          acc[category].data.push(item);
          return acc;
        }, {})
      );

      setGenerateTemplate(groupedData);
    }
  }, [template]);

  // useEffect(() => {
  //   // Mengatur meta tag untuk viewport agar mencegah zoom
  //   const metaTag = document.querySelector('meta[name="viewport"]');
  //   if (metaTag) {
  //     metaTag.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no';
  //   } else {
  //     const newMetaTag = document.createElement('meta');
  //     newMetaTag.name = 'viewport';
  //     newMetaTag.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no';
  //     document.head.appendChild(newMetaTag);
  //   }

  //   // Menambahkan event listener untuk mencegah touch dan zoom
  //   document.documentElement.style.touchAction = 'none'; // Disable touch action globally

  //   return () => {
  //     // Menghapus pengaturan meta tag saat komponen dibersihkan
  //     if (metaTag) {
  //       metaTag.content = 'width=device-width, initial-scale=1';
  //     }
  //     // Mengembalikan pengaturan touchAction ke default
  //     document.documentElement.style.touchAction = 'auto';
  //   };
  // }, []);

  useEffect(() => {
    const handleWheel = event => {
      const playground = document.getElementById('playground');

      if (playground) {
        const rect = playground.getBoundingClientRect();
        const isInside =
          event.clientX >= rect.left &&
          event.clientX <= rect.right &&
          event.clientY >= rect.top &&
          event.clientY <= rect.bottom;

        if (!isInside) return;

        event.preventDefault();

        const container = containerRef.current;
        if (!container) return;

        const rectContainer = container.getBoundingClientRect();
        const offsetX = ((event.clientX - rectContainer.left) / rectContainer.width) * 100;
        const offsetY = ((event.clientY - rectContainer.top) / rectContainer.height) * 100;

        const delta = event.deltaY;
        setScale(prevScale => {
          const newScale = Math.min(3, Math.max(0.5, prevScale - delta * 0.001));
          return newScale;
        });
      }
    };

    window.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  useEffect(() => {
    if (tutorialShelfmate != 'true') {
      dispayTutorial();
    }
  }, []);

  // useEffect(() => {
  //   document.documentElement.classList.add('html-shelfmate-class');
  //   document.body.classList.add('body-shelfmate-class');
  // }, []);

  return (
    <DndProvider
      backend={isMobile ? TouchBackend : HTML5Backend}
      options={{
        enableMouseEvents: true,
        delayTouchStart: 0,
      }}
    >
      <Wrapper
        hideSourceOnDrag={hideSourceOnDrag}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onTouchCancel={handleTouchEnd}
        style={{ touchAction: 'none', overflow: 'hidden' }}
      >
        <Playground
          playgroundRef={containerRef}
          newItem={newitemAdded}
          positionItem={(e: any) => setPositionNewItem(e)}
          setAmount={(e: any) => setAmount(e)}
          template={addTemplate}
          clearTemplate={() => setAddTemplate(null)}
          pinterest={dictionary?.linkPinterestForShelfmateApp}
          isMobile={isMobile}
          transformOrigin={`${origin.x} ${origin.y}`}
          scale={scale}
          userZoom={startDistance}
        />

        {/* Desktop */}
        {!isMobile && (
          <Sidebar
            data={combineDataResult}
            amount={amount}
            template={generateTemplate}
            addTemplate={(e: any) => setAddTemplate(e)}
            totalTemplate={dictionary?.templateShelfmateApp?.length}
            setNewItemAdded={e => newItem(e)}
            tutorial={tutorial}
            tutorialShelfmate={!tutorialShow}
          />
        )}

        {/* Mobile */}
        {isMobile && (
          <HeaderMobile
            data={combineDataResult}
            amount={amount}
            pinterest={dictionary?.linkPinterestForShelfmateApp}
            template={generateTemplate}
            addTemplate={(e: any) => setAddTemplate(e)}
            isMobile={isMobile}
            setNewItemAdded={e => newItem(e)}
            tutorial={tutorial}
          />
        )}
      </Wrapper>
    </DndProvider>
  );
}
const Wrapper = styled.div`
  background: url(${paper});
  background-repeat: repeat;
  position: relative;
  font-family: 'messinaMono';
  overflow: hidden;

  ${desktopBreakpoint} {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
  }
`;
