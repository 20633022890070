import React from 'react';
import styled from 'styled-components';
import { desktopBreakpoint, colors, desktopVW, mobileVW } from '../../styles/index';
import Icons from '../../utils/Icons';

interface Props {
  shape: string;
  checked: boolean;
}

export default function Checkbox(props: Props) {
  const { shape, checked } = props;

  //const shape = 'square'

  return (
    <Wrapper>
      <Icons name={checked ? 'checkChecked' : 'check'} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: inline-block;
  width: ${mobileVW(18)};
  height: ${mobileVW(18)};
  margin: 0 ${mobileVW(5)} 0 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;

  ${desktopBreakpoint} {
    width: 1.5ch;
    height: 1.5ch;
    margin: 0 ${desktopVW(7)} 0 0;
  }
`;
