//@ts-nocheck
import React, { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, desktopBreakpoint, desktopVW, mobileVW } from '../../styles';
import formatPrice from '../../utils/formatPrice';
import { DragPreviewImage } from 'react-dnd';

type Props = {
  image: any;
  shopifyData: any;
  title: string;
  isDrag: boolean;
  preview: any;
  width: number;
  height: number;
  alreadyItem: Number;
  onClick?: Function;
};

const Product = forwardRef<HTMLDivElement, Props>(
  (
    { image, shopifyData, title, isDrag, preview, width, height, alreadyItem = 0, onClick },
    ref
  ) => {
    const [previewVisible, setPreviewVisible] = useState(false);
    useEffect(() => {
      if (isDrag) {
        setPreviewVisible(true);
      } else {
        setPreviewVisible(false);
      }
    }, [isDrag]);

    return (
      <Wrapper>
        <DragPreviewImage connect={preview} src={image} />
        {alreadyItem > 0 && <BoxItemsInCart>{alreadyItem}</BoxItemsInCart>}

        <BoxImage onClick={() => onClick()}>
          <Image src={image} alt={image} />
          {/* @ts-ignore */}
          <Image2 ref={ref} src={image} alt={image} />
        </BoxImage>
        <Title>{title}</Title>
        {shopifyData.compare_at_price === null ? (
          <BoxPrice>
            <Price>{formatPrice(Number(shopifyData.price), '€')}</Price>
          </BoxPrice>
        ) : (
          <BoxPrice>
            <CompatePrice>{formatPrice(Number(shopifyData.price), '€')}</CompatePrice>
            <PriceStrike>
              {' ' + formatPrice(Number(shopifyData.compare_at_price), '€')}
            </PriceStrike>
          </BoxPrice>
        )}
      </Wrapper>
    );
  }
);

export default Product;

const StyledPreviewImage = styled.img`
  position: fixed; /* Mengatur posisi agar selalu terlihat */
  top: 0; /* Sesuaikan posisi */
  left: 0; /* Sesuaikan posisi */
  width: ${({ width }) => desktopVW(width)};
  height: ${({ height }) => desktopVW(height)};
  object-fit: cover;
  border: 2px dashed ${colors.tomato}; /* Contoh border */
  z-index: 1000; /* Pastikan di atas elemen lain */
`;

const Wrapper = styled.div`
  width: ${mobileVW(119.34)};
  margin-right: ${mobileVW(10)};
  margin-bottom: ${mobileVW(10)};
  display: flex;
  flex-direction: column;
  position: relative;
  ${desktopBreakpoint} {
    width: ${desktopVW(110)};
    margin-right: ${desktopVW(20)};
    margin-bottom: ${desktopVW(20)};
  }
`;

const Image = styled.img`
  width: auto;
  height: auto;
  object-fit: contain;
  max-width: ${mobileVW(80)};
  max-height: ${mobileVW(80)};
  ${desktopBreakpoint} {
    max-width: ${desktopVW(80)};
    max-height: ${desktopVW(80)};
  }
`;
const Image2 = styled.img`
  width: auto;
  height: auto;
  object-fit: contain;
  position: absolute;
  opacity: 0;
  max-width: ${mobileVW(200)};
  max-height: ${mobileVW(200)};
  ${desktopBreakpoint} {
    max-width: ${desktopVW(200)};
    max-height: ${desktopVW(200)};
  }
`;

const BoxImage = styled.div`
  width: ${mobileVW(108)};
  height: ${mobileVW(108)};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f2ef;
  position: relative;
  ${desktopBreakpoint} {
    width: ${desktopVW(110)};
    height: ${desktopVW(110)};
  }
`;

const Title = styled.div`
  font-size: ${mobileVW(12)};
  margin-top: ${mobileVW(6)};
  ${desktopBreakpoint} {
    font-size: ${desktopVW(13.5)};
    margin-top: ${desktopVW(10)};
  }
`;

const BoxPrice = styled.div`
  display: flex;
  font-size: ${mobileVW(12)};
  margin-top: ${mobileVW(4)};
  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
    margin-top: ${desktopVW(6)};
  }
`;

const Price = styled.div`
  display: flex;
`;
const PriceStrike = styled.div`
  opacity: 0.4;
  text-decoration: line-through;
`;

const CompatePrice = styled.div`
  display: flex;
  margin-right: ${mobileVW(2)};
  color: ${colors.tomato};
  ${desktopBreakpoint} {
    margin-right: ${desktopVW(5)};
  }
`;
const BoxItemsInCart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: ${colors.tomato};
  z-index: 2;
  color: ${colors.white};
  width: ${mobileVW(18)};
  height: ${mobileVW(18)};
  border-radius: ${mobileVW(10)};
  font-size: ${mobileVW(9)};
  top: ${mobileVW(4)};
  left: ${mobileVW(4)};

  ${desktopBreakpoint} {
    width: ${desktopVW(18)};
    height: ${desktopVW(18)};
    border-radius: ${desktopVW(10)};
    font-size: ${desktopVW(9)};
    top: ${desktopVW(2)};
    left: ${desktopVW(2)};
  }
`;
