import React, { useContext, useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { SettingsContext } from '../../contexts';
import { useGlobalDictionaryQuery } from '../../hooks/utils/useGlobalDictionaryQuery';
import { colors, desktopBreakpoint, desktopVW, mobileVW, zIndex } from '../../styles/index';
import CollectionFilterGaleryAccordionChild from './CollectionFilterGaleryAccordionChild';

interface Props {
  allProductsMaterial: [];
  allProductsArtist: [];
  showFilter: boolean;
  filterIncrement: number;
}

export default function AllProductsFilter(props: Props) {
  const {
    allProductsMaterial,
    allProductsArtist,
    allProductsCategories,
    showFilter,
    filterIncrement,
    getIncrementApply,
    collection,
  } = props;

  const {
    materialFilter,
    setMaterialFilter,
    artistFilter,
    setArtistFilter,
    priceFilter,
    setPriceFilter,
    categoryFilter,
    setCategoryFilter,
  }: {
    materialFilter: any;
    setMaterialFilter: any;
    artistFilter: any;
    setArtistFilter: any;
    priceFilter: any;
    setPricefilter: any;
    categoryFilter: any;
    setCategoryFilter: any;
  } = useContext(SettingsContext);

  const dictionary = useGlobalDictionaryQuery();
  const [filterOpen, setFilterOpen] = useState(false);
  const [incrementApply, setIncrementApply] = useState(0);

  const wrapperSpring = useSpring({
    pointerEvents: filterOpen ? 'auto' : 'none',
    backgroundColor: filterOpen ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0)',
    cursor: filterOpen ? 'pointer' : 'auto',
  });

  const filterSpring = useSpring({
    transform: filterOpen ? 'translateX(-100%)' : 'translateX(0%)',
    config: {
      tension: 160,
      friction: 25,
    },
    delay: 200,
  });
  const filterArt = [
    {
      title: 'material',
      displayTitle: 'Material',
      content: allProductsMaterial,
    },
    {
      title: 'artist',
      displayTitle: 'Artist',
      content: allProductsArtist,
    },
  ];

  const filterSecoundChance = [
    {
      title: 'Price',
      displayTitle: dictionary.price,
      content: false,
    },
    {
      title: 'Category',
      displayTitle: 'category',
      content: allProductsCategories,
    },
  ];

  // {seoTitle === 'dBodhi Art of Craftsmanship' || seoTitle === 'Second chance' ? (

  useEffect(() => {
    setFilterOpen(showFilter);
  }, [filterIncrement]);

  useEffect(() => {
    getIncrementApply(incrementApply);
  }, [incrementApply]);

  return (
    <>
      <Background style={{ ...wrapperSpring }} onClick={() => setFilterOpen(false)} />
      <WrapperFilters style={{ ...filterSpring }}>
        <FilterHeader>
          <p>{dictionary.filters}</p>
          <button type="button" aria-label="close filters" onClick={() => setFilterOpen(false)}>
            {dictionary.close}
          </button>
        </FilterHeader>
        <ScrollOuter>
          <ScrollInner>
            <FilterAccordionWrapper>
              {collection === 'dBodhi Art of Craftsmanship'
                ? filterArt.map(({ title, displayTitle, content }, index) => (
                    <CollectionFilterGaleryAccordionChild
                      filterIndex={index}
                      title={title}
                      displayTitle={displayTitle}
                      content={content}
                      key={title}
                      artistFilter={artistFilter}
                      setArtistFilter={setArtistFilter}
                      materialFilter={materialFilter}
                      setMaterialFilter={setMaterialFilter}
                      priceFilter={priceFilter}
                      setPriceFilter={setPriceFilter}
                      categoryFilter={categoryFilter}
                      setCategoryFilter={setCategoryFilter}
                    />
                  ))
                : null}
              {collection === 'Second chance' || collection === 'Tweedekans'
                ? filterSecoundChance.map(({ title, displayTitle, content }, index) => (
                    <CollectionFilterGaleryAccordionChild
                      filterIndex={index}
                      title={title}
                      displayTitle={displayTitle}
                      content={content}
                      key={title}
                      artistFilter={artistFilter}
                      setArtistFilter={setArtistFilter}
                      materialFilter={materialFilter}
                      setMaterialFilter={setMaterialFilter}
                      priceFilter={priceFilter}
                      setPriceFilter={setPriceFilter}
                      categoryFilter={categoryFilter}
                      setCategoryFilter={setCategoryFilter}
                    />
                  ))
                : null}
            </FilterAccordionWrapper>
          </ScrollInner>
        </ScrollOuter>
        <SearchFooter>
          <button
            type="button"
            aria-label="apply filters"
            onClick={() => {
              setFilterOpen(false);
              setIncrementApply(incrementApply + 1);
            }}
          >
            {dictionary.apply}
          </button>
        </SearchFooter>
      </WrapperFilters>
    </>
  );
}

const Background = styled(animated.div)`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: ${zIndex.header + 2};
  ${desktopBreakpoint} {
    width: 100vw;
  }
`;

const WrapperFilters = styled(animated.div)`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100vw;
  right: -100vw;
  background-color: ${colors.black};
  z-index: ${zIndex.header + 2};
  cursor: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${desktopBreakpoint} {
    width: ${desktopVW(480)};
    right: ${desktopVW(-481)};
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const FilterHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${mobileVW(80)};
  border-bottom: ${mobileVW(1)} solid #3c3b3b;
  padding: 0 ${mobileVW(30)};

  p {
    color: ${colors.white};
    font-family: 'messinaMono', 'Courier New', Courier, monospace;
    font-size: ${mobileVW(15)};
    ${desktopBreakpoint} {
      font-size: ${desktopVW(13)};
    }
  }

  button {
    color: rgba(245, 245, 245, 0.4);
    font-family: 'messinaMono', 'Courier New', Courier, monospace;
    font-size: ${mobileVW(15)};
    ${desktopBreakpoint} {
      font-size: ${desktopVW(13)};
    }
  }

  ${desktopBreakpoint} {
    height: ${desktopVW(120)};
    border-bottom: ${desktopVW(1)} solid #3c3b3b;
    padding: ${desktopVW(10)} ${desktopVW(60)} 0 ${desktopVW(60)};
  }
`;

const FilterAccordionWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: ${mobileVW(0)};
  ${desktopBreakpoint} {
  }
`;

const ScrollOuter = styled.div`
  position: relative;
  z-index: 1;
  overflow-y: scroll;
  width: 100%;
  flex: 2 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ScrollInner = styled.div`
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const SearchFooter = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${mobileVW(20)} ${mobileVW(20)} ${mobileVW(30)} ${mobileVW(20)};

  button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: grey;
    color: ${colors.white};
    font-family: 'messinaMono', 'Courier New', Courier, monospace;
    height: ${mobileVW(70)};
    font-size: ${mobileVW(15)};

    ${desktopBreakpoint} {
      height: ${desktopVW(70)};
      font-size: ${desktopVW(14)};
    }
  }

  ${desktopBreakpoint} {
    padding: ${desktopVW(20)} ${desktopVW(60)} ${desktopVW(55)} ${desktopVW(60)};
  }
`;
