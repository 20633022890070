export const arrowRight =
  'https://images.ctfassets.net/hhqcfrz88uh8/3bO25y3YyQIqQ2WHOmrAhF/101a4b52dae2ce738bb80775550b983a/Vector__5_.svg';
export const arraowDown =
  'https://images.ctfassets.net/hhqcfrz88uh8/3Fu0DAlFRGNSlE40WSUePK/dae583722355255da0612791b4b43b1a/Vector__4_.svg';
export const arraowUp =
  'https://images.ctfassets.net/hhqcfrz88uh8/78rWajNJQjZNiwzNjNHXNL/01fd8c966a5bd42cb3e68cfb2a3073d4/arrow-up__2_.png';
export const arraowDown2 =
  'https://images.ctfassets.net/hhqcfrz88uh8/76i0GAdJtVkyiCh1b2pMOM/23f29daa2254f40f19b8f9450907c11b/arrow-down__1_.png';
export const arrowLeft =
  'https://images.ctfassets.net/hhqcfrz88uh8/5i2wmzdYdhYIDzGOca5N3h/ab933da45e0d1492412ab207552f9f51/download.svg';
export const iconMenuTemplate =
  'https://images.ctfassets.net/hhqcfrz88uh8/2N2eWC7wlbJnc4peSuYDlh/2413f60210c31f817c7ed3e1ed717615/Group_1__1_.png';
export const iconClose =
  'https://images.ctfassets.net/hhqcfrz88uh8/6I5KELYmBurzEGGoHVuqdW/f263e49aeb2a51439462fcb5f3ec186f/icon_close.svg';
export const logo =
  'https://cdn.shopify.com/s/files/1/0473/9570/0896/files/checkout-logo-transparent_png.png?v=1719559804';
export const iconPinteres =
  'https://images.ctfassets.net/hhqcfrz88uh8/3Q4mUzZx8M2bQTvo8fKfYH/5fb2e012fd538801d15e4a731cd4d2f1/pinterest.svg';
export const iconRuler =
  'https://images.ctfassets.net/hhqcfrz88uh8/4COoMkU4JtMbZKMkVDYPni/d84ab648decd42868e6d7b1f154ff9ca/ruler.svg';
export const iconWarning =
  'https://images.ctfassets.net/hhqcfrz88uh8/3i9OmyfxOPaXYr6forVH4Q/95da9587277116182aee9b26653d744d/Icon.png';
