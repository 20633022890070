import React from 'react';
import styled from 'styled-components';
import {
  desktopBreakpoint,
  colors,
  desktopVW,
  mobileVW,
  zIndex,
  mobileContentSidePadding,
} from '../../styles/index';
import Img from 'gatsby-image';
import Icons from '../../utils/Icons';

interface Props {
  heroImage: {
    fluid: string;
  };
  title: string;
  heroTopText: string;
  heroBottomText: string;
}

export default function CollectionHeroSection(props: Props) {
  const { heroImage, title, heroTopText, heroBottomText } = props;

  const longestTitle = Math.max(heroTopText ? heroTopText.length : 0, heroBottomText.length);

  return (
    <Wrapper>
      <StyledImg fluid={heroImage.fluid} loading="eager" fadeIn={true} />
      {heroTopText && <TitleTop char={longestTitle}>{heroTopText}</TitleTop>}
      <TitleBottom char={longestTitle}>
        <TitleBottomContent>
          {heroBottomText}
          <IconWrapper>
            <Icons name="doodle1" />
          </IconWrapper>
        </TitleBottomContent>
      </TitleBottom>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100vw;
  overflow: hidden;
  height: 100vh;
  position: relative;

  ${desktopBreakpoint} {
    height: 100vh;
  }
`;

const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${zIndex.backgrounds};

  ${desktopBreakpoint} {
  }
`;

const TitleTop = styled.h1`
  z-index: ${zIndex.surface};
  position: absolute;
  text-transform: uppercase;
  font-family: 'messina', Arial, Helvetica, sans-serif;
  font-weight: 300;
  color: white;
  top: 45vh;
  left: ${mobileContentSidePadding};
  font-size: ${({ char }) => (char > 7 ? `${mobileVW(43)}` : `${mobileVW(62)}`)};
  transform: translateY(-100%);

  ${desktopBreakpoint} {
    top: ${desktopVW(110)};
    left: ${desktopVW(106)};
    font-size: ${desktopVW(120)};
    transform: translateY(0%);
  }
`;

const TitleBottom = styled.h1`
  z-index: ${zIndex.surface};
  position: absolute;
  text-transform: uppercase;
  font-family: 'messina', Arial, Helvetica, sans-serif;
  font-weight: 300;
  color: white;
  bottom: 55vh;
  right: ${mobileContentSidePadding};
  font-size: ${({ char }) => (char > 7 ? `${mobileVW(43)}` : `${mobileVW(62)}`)};
  transform: translateY(100%);

  ${desktopBreakpoint} {
    bottom: ${desktopVW(121)};
    right: ${desktopVW(106)};
    font-size: ${desktopVW(120)};
    transform: translateY(0%);
  }
`;

const TitleBottomContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  ${desktopBreakpoint} {
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: ${mobileVW(10)};
  left: 0;
  right: auto;

  ${desktopBreakpoint} {
    left: auto;
    right: ${desktopVW(-50)};
    top: ${desktopVW(-10)};
  }
`;
