import React from 'react';
import styled from 'styled-components';
import { colors, desktopBreakpoint, desktopVW, mobileVW } from '../../styles';
import formatPrice from '../../utils/formatPrice';

type Props = {
  title: string;
  shopifyData: any;
  image: any;
  quantity: number;
  shelfmate: boolean;
};

function ItemsCheckout({ shopifyData, title, image, quantity, shelfmate }: Props) {
  return (
    <Wrapper>
      <BoxImage>
        {/* @ts-ignore */}
        <Image shelfmate={shelfmate} src={image} alt={title} />
      </BoxImage>

      {shelfmate && (
        <Content>
          <Title>{title}</Title>
          {shopifyData?.variants[0]?.compare_at_price === null ? (
            <BoxPrice>
              <Price>{formatPrice(Number(shopifyData?.variants[0]?.price), '€')}</Price>
            </BoxPrice>
          ) : (
            <BoxPrice>
              <CompatePrice>
                {formatPrice(Number(shopifyData?.variants[0]?.price), '€')}
              </CompatePrice>
              <PriceStrike>
                {' ' + formatPrice(Number(shopifyData?.variants[0]?.compare_at_price), '€')}
              </PriceStrike>
            </BoxPrice>
          )}
          <Quantity>Quantity : {quantity}</Quantity>
        </Content>
      )}

      {!shelfmate && (
        <Content>
          <Title>{title}</Title>
          {shopifyData?.variants[0]?.compareAtPrice === null ? (
            <BoxPrice>
              <Price>{formatPrice(Number(shopifyData?.variants[0]?.price.amount), '€')}</Price>
            </BoxPrice>
          ) : (
            <BoxPrice>
              <CompatePrice>
                {formatPrice(Number(shopifyData?.variants[0]?.price.amount), '€')}
              </CompatePrice>
              <PriceStrike>
                {' ' + formatPrice(Number(shopifyData?.variants[0]?.compareAtPrice), '€')}
              </PriceStrike>
            </BoxPrice>
          )}
          <Quantity>Quantity : {quantity}</Quantity>
        </Content>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: ${mobileVW(1)} solid ${colors.cappuccino};
  padding: ${mobileVW(16)};
  ${desktopBreakpoint} {
    border-bottom: ${desktopVW(1)} solid ${colors.cappuccino};
    padding: ${desktopVW(20)};
  }
`;

const Image = styled.img`
  width: ${({
    // @ts-ignore
    shelfmate,
  }) => (shelfmate ? 'auto' : '100%')};
  height: ${({
    // @ts-ignore
    shelfmate,
  }) => (shelfmate ? 'auto' : '100%')};
  object-fit: ${({
    // @ts-ignore
    shelfmate,
  }) => (shelfmate ? 'contain' : 'cover')};
  max-width: ${({
    // @ts-ignore
    shelfmate,
  }) => (shelfmate ? mobileVW(50) : '100%')};
  max-height: ${({
    // @ts-ignore
    shelfmate,
  }) => (shelfmate ? mobileVW(50) : '100%')};
  ${desktopBreakpoint} {
    width: ${({
      // @ts-ignore
      shelfmate,
    }) => (shelfmate ? 'auto' : '100%')};
    height: ${({
      // @ts-ignore
      shelfmate,
    }) => (shelfmate ? 'auto' : '100%')};
    object-fit: ${({
      // @ts-ignore
      shelfmate,
    }) => (shelfmate ? 'contain' : 'cover')};
    max-width: ${({
      // @ts-ignore
      shelfmate,
    }) => (shelfmate ? desktopVW(50) : '100%')};
    max-height: ${({
      // @ts-ignore
      shelfmate,
    }) => (shelfmate ? desktopVW(50) : '100%')};
  }
`;

const BoxImage = styled.div`
  width: ${mobileVW(70)};
  height: ${mobileVW(70)};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f2ef;
  ${desktopBreakpoint} {
    width: ${desktopVW(80)};
    height: ${desktopVW(80)};
  }
`;
const Title = styled.div`
  font-size: ${mobileVW(14)};
  ${desktopBreakpoint} {
    font-size: ${desktopVW(16)};
  }
`;

const Quantity = styled.div`
  font-size: ${mobileVW(10)};
  margin-top: ${mobileVW(5)};
  ${desktopBreakpoint} {
    font-size: ${desktopVW(12)};
    margin-top: ${desktopVW(5)};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${mobileVW(12)};
  ${desktopBreakpoint} {
    margin-left: ${desktopVW(15)};
  }
`;

const BoxPrice = styled.div`
  display: flex;
  font-size: ${mobileVW(14)};
  margin-top: ${mobileVW(6)};
  ${desktopBreakpoint} {
    font-size: ${desktopVW(16)};
    margin-top: ${desktopVW(6)};
  }
`;

const Price = styled.div`
  display: flex;
`;
const PriceStrike = styled.div`
  opacity: 0.4;
  text-decoration: line-through;
`;

const CompatePrice = styled.div`
  display: flex;
  margin-right: ${desktopVW(5)};
  color: ${colors.black};
`;

export default ItemsCheckout;
