import React from 'react';
import styled from 'styled-components';
import { colors, desktopBreakpoint, desktopVW, mobileVW } from '../../styles';
import { Link } from 'gatsby';

type Props = {
  onClose: Function;
  pinterest: string;
};

export default function AlertPinterest({ onClose, pinterest }: Props) {
  return (
    <Wrapper>
      <Close
        onClick={() => onClose()}
        src={
          'https://images.ctfassets.net/hhqcfrz88uh8/6I5KELYmBurzEGGoHVuqdW/f263e49aeb2a51439462fcb5f3ec186f/icon_close.svg'
        }
        alt="arrow close"
        width="100"
        height="100"
      />
      <Content>
        <Heading>Need some inspiration?</Heading>
        <Subheading>You can open our pinterest board to get more inspiration</Subheading>
        <Link to={pinterest} target="_blank" rel="noopener noreferrer">
          <ButtonPinterest>
            <StyledImgPinterest
              src={
                'https://images.ctfassets.net/hhqcfrz88uh8/3Q4mUzZx8M2bQTvo8fKfYH/5fb2e012fd538801d15e4a731cd4d2f1/pinterest.svg'
              }
              alt="pinterest dbodhi"
              width="100"
              height="100"
            />
            Open Pinterest
            <StyledImgRightArrow
              src={
                'https://images.ctfassets.net/hhqcfrz88uh8/2BQMSLOtg0EhkVWRy4I6Rc/a5715caffb8d9da8a8e100595d3e1cf6/Group_1.svg'
              }
              alt="arrow right"
              width="100"
              height="100"
            />
          </ButtonPinterest>
        </Link>
      </Content>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  font-family: 'messinaMono';
  background-color: white;
  position: absolute;
  display: flex;
  width: 95%;
  height: auto;
  top: ${mobileVW(178)};
  border: ${mobileVW(1)} solid #c5b9a3;
  margin-left: -1.25%;
  z-index: 1;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: ${mobileVW(16)} ${mobileVW(12)};
  ${desktopBreakpoint} {
    width: 50%;
    padding: ${desktopVW(40)} ${desktopVW(25)};
  }
`;
const Heading = styled.div`
  font-size: ${mobileVW(14)};
  font-weight: 400;
  ${desktopBreakpoint} {
    font-size: ${desktopVW(18)};
  }
`;
const Subheading = styled.div`
  color: #707070;
  font-size: ${mobileVW(12)};
  margin: ${mobileVW(10)} 0;
  white-space: normal;
`;

const Close = styled.img`
  display: flex;
  position: absolute;
  right: ${mobileVW(14)};
  top: ${mobileVW(14)};
  width: ${mobileVW(16)};
  height: ${mobileVW(16)};
  cursor: pointer;
  ${desktopBreakpoint} {
    right: ${desktopVW(14)};
    top: ${desktopVW(14)};
    width: ${desktopVW(16)};
    height: ${desktopVW(16)};
  }
`;
const ButtonPinterest = styled.button`
  font-family: 'messinaMono';
  background-color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: ${mobileVW(9)} ${mobileVW(12)};
  border: ${mobileVW(1)} solid #c5b9a3;
  font-size: ${mobileVW(12)};
  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
    padding: ${desktopVW(8)} ${desktopVW(16)};
    border: ${desktopVW(1)} solid #c5b9a3;
  }
`;
const StyledImgPinterest = styled.img`
  width: ${mobileVW(18)};
  height: ${mobileVW(18)};
  margin-right: ${mobileVW(8)};
  ${desktopBreakpoint} {
    width: ${desktopVW(20)};
    height: ${desktopVW(20)};
    margin-right: ${desktopVW(10)};
  }
`;

const StyledImgRightArrow = styled.img`
  width: ${mobileVW(10.5)};
  height: auto;
  margin-left: ${mobileVW(8)};
  ${desktopBreakpoint} {
    width: ${desktopVW(20)};
    height: ${desktopVW(20)};
    margin-left: ${desktopVW(10)};
  }
`;
