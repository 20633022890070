// @ts-nocheck
import React from 'react';
import styled from 'styled-components';
import { desktopBreakpoint, desktopVW, mobileVW, zIndex } from '../../styles/index';
import ProductThumbnail from '../shared/ProductThumbnail';

interface Props {
  items: {
    title: string;
    slug: string;
    shopifyData: {
      variants: {
        price: string;
        compare_at_price: string;
        sku: string;
      };
    };
    productTag: string;
    thumbnailImages: {
      fluid: string;
    };
  };
}

export default function CollectionGridSection(props: Props) {
  const { items } = props;
  return (
    <GridWrapper>
      {items?.map((item, index) => {
        let stock_variant = item.shopifyData.variants.filter(
          item => item.inventory_quantity != null && item.inventory_quantity > 0
        );
        console.log('slug', item.slug);
        return (
          <ThumbnailWrapper key={item.title + index}>
            <ProductThumbnail
              productId={item?.shopifyData?.variants[0]?.product_id}
              index={index}
              stock={stock_variant.length > 0 ? true : false}
              pageAllProduct={false}
              thumbnailImages={item.thumbnailImages}
              sku={item.shopifyData?.variants[0]?.sku}
              title={item.titleToShow}
              invertedImages={index === 2}
              price={item.shopifyData.variants[0].price}
              compareAtPrice={item.shopifyData.variants[0].compare_at_price}
              slug={item.slug}
              productTag={item.productTag}
              isFullScreen={index === 2}
              isMobileGrid="potato"
              variants={item.shopifyData.variants}
              thumbnailVariantsImages={item.thumbnailVariantsImages}
            />
          </ThumbnailWrapper>
        );
      })}
    </GridWrapper>
  );
}

const GridWrapper = styled.div`
  display: grid;
  padding: ${mobileVW(35)} ${mobileVW(20)} 0 ${mobileVW(20)};
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: ${mobileVW(20)};
  z-index: ${zIndex.surface};

  ${desktopBreakpoint} {
    grid-template-columns: repeat(4, ${desktopVW(310)});
    grid-template-rows: ${desktopVW(455)};
    grid-gap: ${desktopVW(26)};
    padding: 0 ${desktopVW(60)};
  }
`;

const ThumbnailWrapper = styled.div`
  height: ${mobileVW(310)};
  ${desktopBreakpoint} {
    height: ${desktopVW(455)};
  }
  &:nth-child(3) {
    grid-column: span 2;
    height: ${mobileVW(580)};
    ${desktopBreakpoint} {
      height: auto;
    }
  }
`;
