//@ts-nocheck
import React, { useCallback, useEffect, useState, useRef } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { colors, desktopBreakpoint, desktopVW, mobileVW } from '../../styles';
import LineIndicator from './LineIndicator';
import update from 'immutability-helper';
import { BoxItem } from './BoxItem';
import { ItemTypes } from './ItemTypes';
import { useDrop, XYCoord } from 'react-dnd';
import OutsideClickHandler from 'react-outside-click-handler';
import { Link } from 'gatsby';
import AlertDiscount from './AlertDiscount';
import AlertPinterest from './AlertPinterest';
import { arrowLeft, iconClose, iconPinteres, iconRuler, iconWarning, logo } from './Icons';
import LinkTool from '../shared/LinkTool';

type Props = {
  newItem: any;
  hideSourceOnDrag: boolean;
  positionItem: Function;
  setAmount: Function;
  template: any;
  clearTemplate: Function;
  pinterest: string;
  isMobile: boolean;
  scale: number;
  transformOrigin: string;
  playgroundRef: any;
  userZoom: any;
};

export default function Playground({
  newItem,
  hideSourceOnDrag,
  positionItem,
  setAmount,
  template,
  clearTemplate,
  pinterest,
  isMobile,
  scale,
  transformOrigin,
  userZoom,
  playgroundRef,
}: Props) {
  let CANVAS_WIDTH = typeof window !== 'undefined' ? window?.innerWidth : 0;
  let CANVAS_HEIGHT = typeof window !== 'undefined' ? window?.innerHeight : 0;
  const TOLERANCE_LENGTH = 30;
  const SPACE = isMobile ? 10 : 15;
  const SIZE_ITEM = isMobile ? 2 : 3;
  const [showIndicator, setShowIndicator] = useState(true);
  const [dragging, setDragging] = useState(false);
  const [configLine, setConfigLine] = useState({});
  const [modalReset, setModalReset] = useState(false);
  const [modalTemplate, setModalTemplate] = useState(false);
  const [showIndicatorLine, setShowIndicatorLine] = useState(true);
  const [showAlertDiscount, setShowAlertDiscount] = useState(false);
  const [showAlertPinterest, setShowAlertInterest] = useState(true);
  const [distance, setDistance] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');

  const [run, setRun] = useState(true); // Mengontrol apakah Joyride dijalankan
  const [stepIndex, setStepIndex] = useState(0);

  const [boxes, setBoxes] = useState<{
    [key: string]: {
      top: number;
      left: number;
      title: string;
      width: number;
      height: number;
      image: string;
      shopifyData: any;
    };
  }>({});

  const MIN_DISTANCE = 1;
  const convertPixelToCm = (pxValue: string) => {
    const cmValue = (parseInt(pxValue?.split('px')[0]) / 96) * 2.54;
    return cmValue;
  };

  const gridStyles: CSSProperties = {
    width: '100%',
    height: isMobile ? '65vh' : '76vh',
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    marginTop: isMobile ? '16vh' : '0',
  };

  const isOverlapping = (
    top: number,
    left: number,
    width: number,
    height: number,
    idToSkip?: string
  ) => {
    return Object.keys(boxes).some(key => {
      if (key === idToSkip) return false;
      const { top: otherTop, left: otherLeft, width: otherWidth, height: otherHeight } = boxes[key];
      const minTop = otherTop - MIN_DISTANCE;
      const maxTop = otherTop + otherHeight + MIN_DISTANCE;
      const minLeft = otherLeft - MIN_DISTANCE;
      const maxLeft = otherLeft + otherWidth + MIN_DISTANCE;
      const itemBottom = top + height;
      const itemRight = left + width;
      const verticalOverlap = itemBottom > minTop && top < maxTop;
      const horizontalOverlap = itemRight > minLeft && left < maxLeft;
      return verticalOverlap && horizontalOverlap;
    });
  };

  const addTemplate = () => {
    setBoxes(template);
    clearTemplate();
  };

  const handleChange = event => {
    setSelectedOption(event.target.value);
  };

  const detectNewItemHorizontal = (items, id = null) => {
    const tolerance = isMobile ? 10 : 50;
    let newLeft = 0;
    let closestLeft = { value: Infinity, key: '' };
    let closestRight = { value: Infinity, key: '' };
    let dropPosition = '';

    const isHorizontalOverlap = item =>
      (items.top > item.top && items.top < item.top + item.height) ||
      (items.top + items.height > item.top && items.top + items.height < item.top + item.height) ||
      (item.top > items.top && item.top + item.height < items.top + items.height);

    for (const key in boxes) {
      if (key === id) continue;

      const item = boxes[key];

      if (isHorizontalOverlap(item)) {
        const distanceRight = items.left - (item.left + item.width);
        const distanceLeft = item.left - (items.left + items.width);

        if (distanceRight > 0 && distanceRight < closestRight.value) {
          dropPosition = 'right';
          closestRight = { value: distanceRight, key };
        } else if (distanceLeft > 0 && distanceLeft < closestLeft.value) {
          dropPosition = 'left';
          closestLeft = { value: distanceLeft, key };
        }
      }
    }

    if (dropPosition === 'right' && closestRight.value < tolerance) {
      newLeft = boxes[closestRight.key].left + boxes[closestRight.key].width + SPACE;
      return { left: newLeft, top: boxes[closestRight.key].top };
    } else if (dropPosition === 'left' && closestLeft.value < tolerance) {
      newLeft = boxes[closestLeft.key].left - SPACE - items.width;
      return { left: newLeft, top: boxes[closestLeft.key].top };
    }

    return { left: newLeft, top: 0 };
  };

  const detectNewItemVertical = (items: any, id: string = null) => {
    const tolerance = isMobile ? 10 : 50;
    let newTop = 0;
    let bottom = { value: Infinity, key: '' };
    let top = { value: Infinity, key: '' };
    let dropPosition = '';

    for (const key in boxes) {
      if (key === id) continue;
      const item = boxes[key];
      if (
        (items.left > item.left && items.left < item.left + item.width) ||
        (items.left + items.width > item.left &&
          items.left + items.width < item.left + item.width) ||
        (item.left > items.left && item.left + item.width < items.left + items.width)
      ) {
        const distanceFromBottom = items.top - (item.top + item.height);
        const distanceFromTop = item.top - (items.top + items.height);

        if (distanceFromBottom > 0 && distanceFromBottom < bottom.value) {
          dropPosition = 'bottom';
          bottom = { value: distanceFromBottom, key };
        } else if (distanceFromTop > 0 && distanceFromTop < top.value) {
          dropPosition = 'top';
          top = { value: distanceFromTop, key };
        }
      }
    }

    if (dropPosition === 'bottom') {
      const bottomItem = boxes[bottom.key];
      if (items.top - (bottomItem.top + bottomItem.height) < tolerance) {
        newTop = bottomItem.top + bottomItem.height + SPACE;
        return { top: newTop, left: bottomItem.left };
      }
    }

    if (dropPosition === 'top') {
      const topItem = boxes[top.key];
      if (topItem.top - (items.top + items.height) < tolerance) {
        newTop = topItem.top - SPACE - items.height;
        return { top: newTop, left: topItem.left };
      }
    }

    return { top: newTop, left: 0 };
  };

  const addItem = (
    name: string,
    width: string,
    height: string,
    image: string,
    left: number = 0,
    top: number = 0,
    shopifyData: any
  ) => {
    const itemWidth = parseFloat(width);
    const itemHeight = parseFloat(height);

    let newLeft = left;
    let newTop = top;

    if (left === 0 && top === 0) {
      let attempts = 0;
      const maxAttempts = 100;

      do {
        newTop = Math.random() * (CANVAS_HEIGHT - itemHeight - MIN_DISTANCE * 2) + MIN_DISTANCE;
        newLeft = Math.random() * (CANVAS_WIDTH - itemWidth - MIN_DISTANCE * 2) + MIN_DISTANCE;
        attempts++;
      } while (isOverlapping(newTop, newLeft, itemWidth, itemHeight) && attempts < maxAttempts);

      if (attempts === maxAttempts) {
        newTop = Math.max(newTop, MIN_DISTANCE);
        newLeft = Math.max(newLeft, MIN_DISTANCE);
      }
    }

    const newId = `box-${shopifyData?.variants[0]?.id}-${Date.now()}`;
    let position = {
      left: newLeft,
      top: newTop,
      lastLeft: left + width,
      lastTop: top + height,
      width: width,
      height: height,
    };

    let leftFix = newLeft;
    let topFix = newTop;

    if (boxes && Object?.keys(boxes)?.length === 0) {
      let detect = detectNewItemHorizontal(position, null);
      let detect2 = detectNewItemVertical(position);
      if (detect.left != 0) {
        leftFix = detect.left;
      }
      if (detect2.top != 0) {
        topFix = detect2.top;
      }
      if (detect2.top == 0) {
        if (
          (position.top - detect.top < TOLERANCE_LENGTH &&
            position.top - detect.top > -TOLERANCE_LENGTH) ||
          (detect.top - position.top < TOLERANCE_LENGTH &&
            detect.top - position.top > -TOLERANCE_LENGTH)
        ) {
          topFix = detect.top;
        }
      }

      if (detect.left == 0) {
        if (
          (position.left - detect2.left < TOLERANCE_LENGTH &&
            position.left - detect2.left > -TOLERANCE_LENGTH) ||
          (detect2.left - position.left < TOLERANCE_LENGTH &&
            detect2.left - position.left > -TOLERANCE_LENGTH)
        ) {
          leftFix = detect2.left;
        }
      }
    }

    setBoxes(prevBoxes => ({
      ...prevBoxes,
      [newId]: {
        top: topFix,
        left: leftFix,
        title: name,
        width: itemWidth,
        height: itemHeight,
        image: image,
        shopifyData: shopifyData,
      },
    }));
  };

  const onRemoveItem = (id: string) => {
    const { [id]: _, ...newData } = boxes;
    setBoxes(newData);
  };

  const handleDrop = (item: any) => {
    addItem(
      item?.name,
      item?.width,
      item?.height,
      item?.image,
      item?.left || 0,
      item?.top || 0,
      item?.shopifyData
    );
  };

  const [, drop] = useDrop({
    accept: ItemTypes.BOX,
    drop(item: any, monitor) {
      setDragging(false);
      const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;

      if (delta && !item?.id) {
        const canvasRect = monitor.getSourceClientOffset() as DOMRect;

        const paddingLeft = isMobile ? window?.innerWidth : (4.86 * window?.innerWidth) / 100;
        const paddingTop = (14.93 * window?.innerWidth) / 100;

        handleDrop({
          name: item?.name,
          width: item?.width,
          height: item?.height,
          image: item?.image,
          shopifyData: item?.shopifyData,
          left: canvasRect.x - paddingLeft,
          top: canvasRect.y - paddingTop,
        });
      }

      return undefined;
    },
    hover(item, monitor) {
      if (monitor.isOver({ shallow: true }) && userZoom == 0) {
        setDragging(true);

        const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
        if (delta) {
          const newLeft = Math.max(0, Math.round(item?.left + delta.x / scale));
          const newTop = Math.max(0, Math.round(item?.top + delta.y / scale));

          let leftFix = newLeft;
          let topFix = newTop;

          if (item?.id) {
            let position = {
              left: newLeft,
              top: newTop,
              lastLeft: newLeft + item.width,
              lastTop: newTop + item.height,
              width: item.width,
              height: item.height,
            };
            let detect = detectNewItemHorizontal(position, item.id);
            let detect2 = detectNewItemVertical(position, item.id);

            if (detect.left != 0) {
              leftFix = detect.left;
            }
            if (detect2.top != 0) {
              topFix = detect2.top;
            }
            if (detect2.top == 0) {
              if (
                (position.top - detect.top < TOLERANCE_LENGTH &&
                  position.top - detect.top > -TOLERANCE_LENGTH) ||
                (detect.top - position.top < TOLERANCE_LENGTH &&
                  detect.top - position.top > -TOLERANCE_LENGTH)
              ) {
                topFix = detect.top;
              }
            }
            if (detect.left == 0) {
              if (
                (position.left - detect2.left < TOLERANCE_LENGTH &&
                  position.left - detect2.left > -TOLERANCE_LENGTH) ||
                (detect2.left - position.left < TOLERANCE_LENGTH &&
                  detect2.left - position.left > -TOLERANCE_LENGTH)
              ) {
                leftFix = detect2.left;
              }
            }

            moveBox(item?.id, leftFix, topFix);
          }
        }

        return undefined;
      }
    },
  });

  const getInformationLine = () => {
    let firstLeft = Infinity;
    let lastLeft = -Infinity;

    let firstTop = Infinity;
    let lastTop = -Infinity;

    let firstTopItem = Infinity;
    let lastTopItem = -Infinity;
    let firstLeftItem = Infinity;
    let lastLeftItem = -Infinity;

    let totalWidth = 0;
    let totalHeight = 0;
    let totalPrice = 0;
    let totalComparePrice = 0;

    Object?.entries(boxes)?.forEach(([key, value]) => {
      const { left, width, top, height, shopifyData } = value;
      const price = Number(shopifyData?.variants[0]?.price);
      const compareAtPrice = Number(shopifyData?.variants[0]?.compare_at_price);

      totalPrice += price;
      if (compareAtPrice) {
        totalComparePrice += compareAtPrice - price;
      }

      if (left < firstLeft) firstLeft = left;
      if (left + width > lastLeft) lastLeft = left + width;
      if (top < firstTop) firstTop = top;
      if (top + height > lastTop) lastTop = top + height;
    });

    if (firstLeft !== Infinity && lastLeft !== -Infinity) {
      totalWidth = (lastLeft - firstLeft) / SIZE_ITEM;
    }

    if (firstTop !== Infinity && lastTop !== -Infinity) {
      totalHeight = (lastTop - firstTop) / SIZE_ITEM;
    }

    firstTopItem = firstTop;
    lastTopItem = lastTop;
    firstLeftItem = firstLeft;
    lastLeftItem = lastLeft;

    setAmount({ price: totalPrice, compareAtPrice: totalComparePrice, data: boxes });

    if (!isMobile) {
      let left = (4.8 / 100) * window.innerWidth;
      firstLeft = firstLeft - left;
      let top = (14.8 / 100) * window.innerWidth;
      firstTop = firstTop - top;
    }

    const data = {
      firstLeft,
      lastLeft,
      firstTop,
      lastTop,
      totalWidth,
      totalHeight,
      totalPrice,
      totalComparePrice,
      firstTopItem,
      lastTopItem,
      firstLeftItem,
      lastLeftItem,
    };

    setConfigLine(data);
  };

  const onResetItems = () => {
    setBoxes({});
    localStorage.setItem('itemPlayground', {});
    setModalReset(false);
  };

  const secoundPositionNewItem = (item: any) => {
    let position;
    let left = 0;
    let top = 0;
    let additionalLeft = (isMobile ? 1 : 6.5 / 100) * 1440;
    let space = isMobile ? 10 : 15;
    let spaceRounded = isMobile ? 2 : 3;

    if (
      configLine.lastLeftItem + space + newItem.width <
      window.innerWidth * (isMobile ? 1 : 0.65)
    ) {
      left = configLine.lastLeftItem + space;
      top = configLine.firstTopItem;

      return { left, top };
    } else if (configLine.firstLeftItem - space - newItem.width > 20) {
      left = configLine.firstLeftItem - space - newItem.width;
      top = configLine.firstTopItem;
      return { left, top };
    } else {
      left = configLine.firstLeftItem + space;
      top = configLine.lastTopItem + space;
      return { left, top };
    }
  };

  function formatHeight(totalHeight) {
    if (totalHeight % 1 !== 0) {
      return totalHeight?.toFixed(1);
    } else {
      return totalHeight?.toString();
    }
  }
  useEffect(() => {
    let prevBoxes = localStorage.getItem('itemPlayground');

    if (prevBoxes != {}) {
      setBoxes(JSON.parse(prevBoxes));
    } else {
      setBoxes({});
    }
  }, []);

  useEffect(() => {
    if (!dragging && boxes != null) {
      getInformationLine();
      localStorage.setItem('itemPlayground', JSON.stringify(boxes));
    }
  }, [boxes, dragging]);

  useEffect(() => {
    if (mobileVW && template != null) {
      addTemplate();
    }
  }, [template]);

  const moveBox = useCallback(
    (id: string, left: number, top: number) => {
      const box = boxes[id];
      if (box) {
        const { width, height } = box;
        if (!isOverlapping(top, left, width, height, id)) {
          setBoxes(
            update(boxes, {
              [id]: {
                $merge: { left, top },
              },
            })
          );
        }
      }
    },
    [boxes]
  );

  useEffect(() => {
    if (newItem) {
      let left = 0;
      let top = 0;
      let position = secoundPositionNewItem(newItem);
      if (boxes == null || Object?.keys(boxes)?.length === 0) {
        left = (window?.innerWidth - newItem.width) / (isMobile ? 2 : 3);
        top = (window?.innerHeight - newItem.height) / (isMobile ? 4 : 5);
      } else {
        top = position.top;
        left = position.left;
      }
      if (newItem?.name) {
        addItem(
          newItem?.name,
          newItem?.width,
          newItem?.height,
          newItem?.image,
          left,
          top,
          newItem?.shopifyData
        );
      }
    }
  }, [newItem]);

  const Indicator = () => {
    return showIndicator ? (
      <div>
        <LineIndicator
          width={1.1}
          height={(configLine.totalHeight * SIZE_ITEM)?.toFixed(0)}
          left={isMobile ? 16 : 30}
          top={configLine.firstTop}
          vertical={true}
          length={formatHeight(configLine.totalHeight)}
          isMobile={isMobile ? true : false}
        />
        <LineIndicator
          width={(configLine.totalWidth * SIZE_ITEM)?.toFixed(0)}
          height={0.5}
          left={configLine.firstLeft}
          top={isMobile ? 10 : -90}
          vertical={false}
          length={formatHeight(configLine.totalWidth)}
          isMobile={isMobile ? true : false}
        />
      </div>
    ) : null;
  };

  const IndicatorSpace = () => (
    <IndicatorSpaceWrapper>
      <IndicatorSpaceContent>
        W:{formatHeight(configLine.totalWidth)} cm x H:{formatHeight(configLine.totalHeight)} cm
      </IndicatorSpaceContent>
    </IndicatorSpaceWrapper>
  );

  const HeaderPalyground = () => {
    return (
      <HeaderPalygroundWrapper>
        <ContentHeaderPalyground>
          <LinkTool to="/shelfmate">
            <HeaderPalygroundLeft>
              <ButtonBack>
                <img src={arrowLeft} alt="back" width="100" height="100" />
              </ButtonBack>
              <Logo src={logo} alt="dbodhi" width="100" height="100" />
            </HeaderPalygroundLeft>
          </LinkTool>

          <HeaderPalygroundRight>
            Shelf<b>Mate</b> - PLAYGROUND
          </HeaderPalygroundRight>
        </ContentHeaderPalyground>
        <ContentHeaderPalyground>
          <a href={pinterest} target="_blank" rel="noopener noreferrer">
            <ButtonPinterest>
              <StyledImgPinterest
                src={iconPinteres}
                alt="pinterest dbodhi"
                width="100"
                height="100"
              />
              {isMobile ? 'for Inspiration' : ' Open Pinterest for Inspiration'}

              <StyledImgRightArrow
                src={
                  'https://images.ctfassets.net/hhqcfrz88uh8/2BQMSLOtg0EhkVWRy4I6Rc/a5715caffb8d9da8a8e100595d3e1cf6/Group_1.svg'
                }
                alt="arrow right"
                width="100"
                height="100"
              />
            </ButtonPinterest>
          </a>
          {boxes != null && Object.keys(boxes).length != 0 && (
            <ButtonReset onClick={() => setModalReset(true)}>Reset</ButtonReset>
          )}
        </ContentHeaderPalyground>
      </HeaderPalygroundWrapper>
    );
  };

  // Definisi langkah-langkah

  return (
    <Wrapper id="playground">
      {!isMobile && boxes != null && <HeaderPalyground />}
      {showAlertDiscount && (
        <AlertDiscount isMobile={isMobile} onClose={() => setShowAlertDiscount(false)} />
      )}

      <Content ref={drop} style={gridStyles}>
        <ContentInner>
          {isMobile && (
            <>
              <PlaygroundTitleMobile>
                Shelf<span>Mate</span> - PLAYGROUND
              </PlaygroundTitleMobile>
              <ContentHeaderPalyground>
                <Link to={pinterest} target="_blank" rel="noopener noreferrer">
                  <ButtonPinterest>
                    <StyledImgPinterest
                      src={iconPinteres}
                      alt="pinterest dbodhi"
                      width="100"
                      height="100"
                    />
                    {isMobile ? 'for Inspiration' : ' Open Pinterest for Inspiration'}

                    <StyledImgRightArrow
                      src={
                        'https://images.ctfassets.net/hhqcfrz88uh8/2BQMSLOtg0EhkVWRy4I6Rc/a5715caffb8d9da8a8e100595d3e1cf6/Group_1.svg'
                      }
                      alt="arrow right"
                      width="100"
                      height="100"
                    />
                  </ButtonPinterest>
                </Link>
                {boxes != null && Object.keys(boxes).length != 0 && (
                  <ButtonReset onClick={() => setModalReset(true)}>Reset</ButtonReset>
                )}
              </ContentHeaderPalyground>
            </>
          )}

          <ContentInnerBoxes
            ref={playgroundRef}
            style={{
              transform: `scale(${scale})`,
              transformOrigin: `${transformOrigin}`,
              transition: 'transform 0.1s ease-out',
              touchAction: 'none',
              left: 0,
              position: 'fixed',
            }}
          >
            {showIndicatorLine && boxes != null && Object?.keys(boxes)?.length != 0 && (
              <Indicator />
            )}
            {boxes != null &&
              Object.keys(boxes).map(key => {
                const { left, top, title, width, height, image } = boxes[key] as {
                  top: number;
                  left: number;
                  title: string;
                  width: number;
                  height: number;
                  image: string;
                };

                return (
                  <BoxItem
                    key={key}
                    id={key}
                    left={left}
                    top={top}
                    width={width}
                    height={height}
                    image={image}
                    dragging={dragging}
                    onDelete={(drag: any) => onRemoveItem(drag)}
                    hideSourceOnDrag={hideSourceOnDrag}
                    isMobile={isMobile}
                  >
                    {image !== '' ? (
                      <img
                        src={image}
                        alt={key}
                        width="100%"
                        height="100%"
                        style={{
                          objectFit: 'cover',
                          width: '100%',
                          height: '100%',
                        }}
                      />
                    ) : (
                      title
                    )}
                  </BoxItem>
                );
              })}
          </ContentInnerBoxes>
        </ContentInner>
      </Content>
      <IndicatorSpace />
      <StyledImgIconFooterWrapper>
        <StyledImgIconFooter
          onClick={() => setShowIndicatorLine(!showIndicatorLine)}
          src={iconRuler}
          alt="icon config"
          width="100"
          height="100"
        />
      </StyledImgIconFooterWrapper>
      {modalReset && (
        <Modal>
          <OutsideClickHandler onOutsideClick={() => setModalReset(false)}>
            <ModalContent>
              <BoxStyledImgClose
                className="component-shelfmate-remove"
                onClick={() => setModalReset(false)}
              >
                <StyledImgClose src={iconClose} alt="arrow close" width="100" height="100" />
              </BoxStyledImgClose>
              <StyledImgWarning src={iconWarning} alt="warning" width={100} height={100} />
              <Heading>Are you sure want to reset?</Heading>
              <SubHeading>
                Lorem ipsum dolor sit amet consectetur. Commodo laoreet mollis
              </SubHeading>
              <BoxButton>
                <Button onClick={() => setModalReset(false)}>Cancel</Button>
                {/* @ts-ignore */}
                <Button colorRed={true} onClick={() => onResetItems()}>
                  Reset
                </Button>
              </BoxButton>
            </ModalContent>
          </OutsideClickHandler>
        </Modal>
      )}

      {template != null && !isMobile && (
        <Modal>
          <OutsideClickHandler onOutsideClick={() => clearTemplate()}>
            <ModalContent>
              <BoxStyledImgClose
                className="component-shelfmate-remove"
                onClick={() => clearTemplate()}
              >
                <StyledImgClose src={iconClose} alt="arrow close" width="100" height="100" />
              </BoxStyledImgClose>
              <StyledImgWarning src={iconWarning} alt="warning" width={100} height={100} />
              <Heading>Are you sure want add template?</Heading>
              <SubHeading>
                Lorem ipsum dolor sit amet consectetur. Commodo laoreet mollis
              </SubHeading>
              <BoxButton>
                <Button onClick={() => clearTemplate()}>Cancel</Button>
                {/* @ts-ignore */}
                <Button onClick={() => addTemplate()}>Continue</Button>
              </BoxButton>
            </ModalContent>
          </OutsideClickHandler>
        </Modal>
      )}
    </Wrapper>
  );
}

const DangerSense = styled.div`
  width: 200px;
  height: 200px;
  background-color: red;
`;

const Wrapper = styled.div`
  position: relative;
  transition: opacity 0.3s ease-in-out;
  ${desktopBreakpoint} {
    width: 66.67%;
    height: 100%;
    padding: ${desktopVW(20)} ${desktopVW(30)} ${desktopVW(30)} ${desktopVW(30)};
  }
`;

const HeaderPalygroundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${desktopBreakpoint} {
    height: ${desktopVW(145)};
  }
`;

const ContentHeaderPalyground = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-left: ${mobileVW(16)};
  padding-right: ${mobileVW(16)};
  padding-top: ${mobileVW(6)};
  ${desktopBreakpoint} {
    margin-bottom: ${desktopVW(30)};
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }
`;

const HeaderPalygroundLeft = styled.div`
  display: flex;
  align-items: center;
`;
const HeaderPalygroundRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: ${desktopVW(2.38)};
  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
  }
`;
const ButtonBack = styled.button`
  background-color: #fff;
  width: ${desktopVW(44)};
  height: ${desktopVW(44)};
  border-radius: ${desktopVW(22)};
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${desktopVW(1)} solid #c5b9a3;

  img {
    font-size: ${desktopVW(24)};
    width: ${desktopVW(24)};
    height: ${desktopVW(24)};
    padding-left: ${desktopVW(4)};
  }
`;
const Logo = styled.img`
  width: ${desktopVW(93)};
  height: auto;
  margin-left: ${desktopVW(12)};
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  ${desktopBreakpoint} {
    padding: ${desktopVW(50)} ${desktopVW(40)} ${desktopVW(60)} ${desktopVW(40)};
  }
`;
const ContentInner = styled.div`
  width: 100%;
  height: 76vh;
  ${desktopBreakpoint} {
  }
`;

const ButtonPinterest = styled.button`
  font-family: 'messinaMono';
  background-color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: ${mobileVW(9)} ${mobileVW(12)};
  border: ${mobileVW(1)} solid #c5b9a3;
  font-size: ${mobileVW(12)};
  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
    padding: ${desktopVW(8)} ${desktopVW(16)};
    border: ${desktopVW(1)} solid #c5b9a3;
  }
`;

const ButtonReset = styled.div`
  font-family: 'messinaMono';
  background-color: transparent;
  font-weight: 500;
  cursor: pointer;
  font-size: ${mobileVW(14)};
  border-radius: ${mobileVW(14)};
  padding: ${mobileVW(8)} ${mobileVW(16)};
  border: ${mobileVW(1)} solid #c5b9a3;
  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
    border-radius: ${desktopVW(14)};
    padding: ${desktopVW(8)} ${desktopVW(16)};
    border: ${desktopVW(1)} solid #c5b9a3;
  }
`;
const StyledImgPinterest = styled.img`
  width: ${mobileVW(18)};
  height: ${mobileVW(18)};
  margin-right: ${mobileVW(8)};
  ${desktopBreakpoint} {
    width: ${desktopVW(20)};
    height: ${desktopVW(20)};
    margin-right: ${desktopVW(10)};
  }
`;

const StyledImgRightArrow = styled.img`
  width: ${mobileVW(10.5)};
  height: auto;
  margin-left: ${mobileVW(8)};
  ${desktopBreakpoint} {
    width: ${desktopVW(10)};
    height: ${desktopVW(10)};
    margin-left: ${desktopVW(10)};
  }
`;

const IndicatorSpaceWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  left: 0;
  width: 100%;
  bottom: ${mobileVW(150)};
  ${desktopBreakpoint} {
    bottom: ${desktopVW(20)};
    width: 66.67%;
  }
`;

const IndicatorSpaceContent = styled.div`
  display: flex;
  background-color: ${colors.black};
  color: ${colors.white};
  font-size: ${mobileVW(10)};
  border-radius: ${mobileVW(14)};
  padding: ${mobileVW(8)} ${mobileVW(16)};
  font-family: 'messinaMono';
  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
    border-radius: ${desktopVW(14)};
    padding: ${desktopVW(8)} ${desktopVW(16)};
  }
`;
const StyledImgIconFooter = styled.img`
  cursor: pointer;
  width: ${mobileVW(20)};
  height: ${mobileVW(20)};
  ${desktopBreakpoint} {
    width: ${desktopVW(24)};
    height: ${desktopVW(24)};
  }
`;

const StyledImgIconFooterWrapper = styled.div`
  width: ${mobileVW(32)};
  height: ${mobileVW(32)};
  border-radius: ${mobileVW(32)};
  position: fixed;
  bottom: ${mobileVW(150)};
  left: ${mobileVW(16)};
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${desktopVW(1)} solid #c5b9a3;
  ${desktopBreakpoint} {
    width: ${desktopVW(44)};
    height: ${desktopVW(44)};
    border-radius: ${desktopVW(44)};

    bottom: ${desktopVW(20)};
    left: ${desktopVW(20)};

    border: ${desktopVW(1)} solid #c5b9a3;
    background-color: ${colors.white};
  }
`;

const Modal = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ModalContent = styled.div`
  background-color: ${colors.white};
  position: relative;
  width: ${mobileVW(343)};
  height: ${mobileVW(233)};
  padding: ${mobileVW(20)} ${mobileVW(16)} ${mobileVW(16)} ${mobileVW(16)};
  ${desktopBreakpoint} {
    width: ${desktopVW(432)};
    height: ${desktopVW(310)};
    padding: ${desktopVW(40)} ${desktopVW(38)} ${desktopVW(30)} ${desktopVW(38)};
  }
`;

const StyledImgWarning = styled.img`
  width: ${mobileVW(33)};
  height: auto;
  ${desktopBreakpoint} {
    width: ${desktopVW(48)};
    height: ${desktopVW(42.67)};
  }
`;

const Heading = styled.div`
  font-size: ${mobileVW(16)};
  margin-top: ${mobileVW(12)};
  ${desktopBreakpoint} {
    font-size: ${desktopVW(18)};
    margin-top: ${desktopVW(27)};
  }
`;

const SubHeading = styled.div`
  color: ${colors.subtleGrey};
  font-size: ${mobileVW(14)};
  margin-top: ${mobileVW(10)};
  ${desktopBreakpoint} {
    font-size: ${desktopVW(16)};
    margin-top: ${desktopVW(10)};
  }
`;

const BoxButton = styled.div`
  display: flex;
  margin-top: ${mobileVW(30)};
  ${desktopBreakpoint} {
    margin-top: ${desktopVW(50)};
  }
`;

const Button = styled.div`
  display: flex;
  transition: background-color 0.2s ease, color 0.2s ease;
  font-size: ${mobileVW(14)};
  padding: ${mobileVW(13.5)} ${mobileVW(24)};
  margin-right: ${mobileVW(10)};
  border: ${mobileVW(1)} solid
    ${({
      // @ts-ignore
      colorRed,
    }) => (colorRed ? colors.tomato : '#C5B9A3')};
  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
    padding: ${desktopVW(16.5)} ${desktopVW(24)};
    margin-right: ${desktopVW(15)};
    border: ${desktopVW(1)} solid
      ${({
        // @ts-ignore
        colorRed,
      }) => (colorRed ? colors.tomato : '#C5B9A3')};
    color: ${({
      // @ts-ignore
      colorRed,
    }) => (colorRed ? colors.tomato : colors.black)};
    cursor: pointer;
  }

  &:hover {
    background-color: ${({
      // @ts-ignore
      colorRed,
    }) => (colorRed ? colors.tomato : '#C5B9A3')};
    color: ${({
      // @ts-ignore
      colorRed,
    }) => (colorRed ? colors.white : colors.black)};
  }
`;

const BoxStyledImgClose = styled.div`
  display: flex;
  background: white;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
  width: ${mobileVW(24)};
  height: ${mobileVW(24)};
  top: ${mobileVW(11)};
  right: ${mobileVW(11)};
  border-radius: ${mobileVW(12)};
  ${desktopBreakpoint} {
    width: ${desktopVW(24)};
    height: ${desktopVW(24)};
    top: -${desktopVW(11)};
    right: -${desktopVW(11)};
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: ${desktopVW(12)};
  }
`;

const StyledImgClose = styled.img`
  width: ${mobileVW(16)};
  height: ${mobileVW(16)};
  ${desktopBreakpoint} {
    width: ${desktopVW(8)};
    height: ${desktopVW(8)};
  }
`;

const PlaygroundTitleMobile = styled.div`
  margin-top: ${mobileVW(25)};
  margin-left: ${mobileVW(16)};
  font-size: ${mobileVW(10)};
  font-weight: 400;
  text-transform: uppercase;
  span {
    font-weight: bold;
  }
`;

const WrapperButtonZoom = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  bottom: ${mobileVW(153)};
  right: ${mobileVW(20)};
  z-index: 3;

  p {
    width: ${mobileVW(40)};
    font-size: ${mobileVW(13)};
    display: flex;
    justify-content: center;
  }

  button {
    width: ${mobileVW(22)};
    height: ${mobileVW(22)};
    font-size: ${mobileVW(12)};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #262525;
    color: ${colors.white};
    border-raidus: ${mobileVW(1)};
  }
`;

const ContentInnerBoxes = styled.div`
  ${desktopBreakpoint} {
    position: relative;
  }
`;

const ZoomComponent = styled.div`
  position: absolute;
  right: ${desktopVW(20)};
  bottom: ${desktopVW(20)};
  z-index: 4;
`;
const Select = styled.select`
  padding: ${desktopVW(6)} ${desktopVW(3)};
  font-size: ${desktopVW(12)};
  border: transparent;
  border-radius: ${desktopVW(4)};
  background-color: ${colors.black};
  color: ${colors.white};
  font-family: 'messinaMono';
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const Option = styled.option`
  font-size: ${desktopVW(12)};
  padding: ${desktopVW(5)} ${desktopVW(5)};
  background-color: ${colors.black};
  color: ${colors.white};
`;
