import React, { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, desktopBreakpoint, desktopVW, mobileVW } from '../../styles';
import formatPrice from '../../utils/formatPrice';
import { DragPreviewImage } from 'react-dnd';

type Props = {
  image: any;
  title: string;
  price: number;
  data: any;
  onClick: Function;
};

const ProductTemplates = forwardRef<HTMLDivElement, Props>(
  ({ image, title, price, data, onClick }, ref) => {
    return (
      <Wrapper onClick={() => onClick(data)}>
        <BoxImage>
          <Image src={image} alt={image} />
          {/* @ts-ignore */}
          <Image2 ref={ref} src={image} alt={image} />
        </BoxImage>

        <Title>{title}</Title>
        <BoxPrice>
          <Price>{formatPrice(price, '€')}</Price>
        </BoxPrice>
      </Wrapper>
    );
  }
);

export default ProductTemplates;

const StyledPreviewImage = styled.img`
  position: fixed; /* Mengatur posisi agar selalu terlihat */
  top: 0; /* Sesuaikan posisi */
  left: 0; /* Sesuaikan posisi */
  width: ${({ width }) => desktopVW(width)};
  height: ${({ height }) => desktopVW(height)};
  object-fit: cover;
  border: 2px dashed ${colors.tomato}; /* Contoh border */
  z-index: 1000; /* Pastikan di atas elemen lain */
`;

const Wrapper = styled.div`
  width: ${mobileVW(119.34)};
  margin-right: ${mobileVW(10)};
  margin-bottom: ${mobileVW(10)};
  display: flex;
  flex-direction: column;
  position: relative;
  ${desktopBreakpoint} {
    width: ${desktopVW(110)};
    margin-right: ${desktopVW(20)};
    margin-bottom: ${desktopVW(20)};
  }
`;

const Image = styled.img`
  width: auto;
  height: auto;
  object-fit: contain;
  max-width: ${mobileVW(80)};
  max-height: ${mobileVW(80)};
  ${desktopBreakpoint} {
    max-width: ${desktopVW(80)};
    max-height: ${desktopVW(80)};
  }
`;
const Image2 = styled.img`
  width: auto;
  height: auto;
  object-fit: contain;
  position: absolute;
  opacity: 0;
  max-width: ${mobileVW(200)};
  max-height: ${mobileVW(200)};
  ${desktopBreakpoint} {
    max-width: ${desktopVW(200)};
    max-height: ${desktopVW(200)};
  }
`;

const BoxImage = styled.div`
  width: ${mobileVW(108)};
  height: ${mobileVW(108)};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f2ef;
  position: relative;
  ${desktopBreakpoint} {
    width: ${desktopVW(110)};
    height: ${desktopVW(110)};
  }
`;

const Title = styled.div`
  font-size: ${mobileVW(12)};
  margin-top: ${mobileVW(6)};
  ${desktopBreakpoint} {
    font-size: ${desktopVW(13.5)};
    margin-top: ${desktopVW(10)};
  }
`;

const BoxPrice = styled.div`
  display: flex;
  font-size: ${mobileVW(12)};
  margin-top: ${mobileVW(4)};
  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
    margin-top: ${desktopVW(6)};
  }
`;
const Price = styled.div`
  display: flex;
`;
const PriceStrike = styled.div`
  opacity: 0.4;
  text-decoration: line-through;
`;

const CompatePrice = styled.div`
  display: flex;
  margin-right: ${desktopVW(5)};
  color: ${colors.tomato};
`;
