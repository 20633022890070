import React from 'react';
import styled from 'styled-components';
import { desktopBreakpoint, desktopVW, mobileVW } from '../../styles';

type Props = {
  width: number;
  height: number;
  left: number;
  top: number;
  vertical: boolean;
  length: number;
  isMobile: boolean;
  scale: any;
};

interface WrapperProps {
  width: number;
  height: number;
  left: number;
  top: number;
  vertical: boolean;
  length: number;
}

export default function LineIndicator({
  width,
  height,
  left,
  top,
  vertical,
  length,
  isMobile,
  scale,
}: Props) {
  if (isMobile) {
    return (
      <WrapperMobile
        width={width}
        height={height}
        left={left}
        top={top}
        vertical={vertical}
        length={length}
        style={{
          transform: `scale(${scale})`,
        }}
      >
        {vertical ? (
          <ContentVertical>
            <CircleVertical />
            <TitleVertical> {length + ' '}cm</TitleVertical>
            <CircleVertical />
          </ContentVertical>
        ) : (
          <ContentHorizontal>
            <CircleHorizontal />
            <TitleHorizontal> {length + ' '}cm</TitleHorizontal>
            <CircleHorizontal />
          </ContentHorizontal>
        )}
      </WrapperMobile>
    );
  } else {
    return (
      <Wrapper
        width={width}
        height={height}
        left={left}
        top={top}
        vertical={vertical}
        length={length}
      >
        {vertical ? (
          <ContentVertical>
            <CircleVertical />
            <TitleVertical> {length + ' '}cm</TitleVertical>
            <CircleVertical />
          </ContentVertical>
        ) : (
          <ContentHorizontal>
            <CircleHorizontal />
            <TitleHorizontal> {length + ' '}cm</TitleHorizontal>
            <CircleHorizontal />
          </ContentHorizontal>
        )}
      </Wrapper>
    );
  }
}

// Apply the props type to the Wrapper component
const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  display: flex;
  justify-content: space-between;
  background-color: #000;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  left: ${({ left }) => left}px;
  top: ${({ top }) => top}px;
  margin-top: ${({ vertical }) => (vertical ? desktopVW(215) : desktopVW(30))};
  margin-left: ${({ vertical }) => (vertical ? desktopVW(0) : desktopVW(70))};
`;
const WrapperMobile = styled.div<WrapperProps>`
  position: fixed;
  display: flex;
  justify-content: space-between;
  background-color: #000;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  left: ${({ left }) => left}px;
  top: ${({ top }) => top}px;
  margin-top: ${({ vertical }) => (vertical ? mobileVW(0) : mobileVW(10))};
`;

const ContentVertical = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: ${desktopVW(25)};
  flex-direction: column;
`;

const CircleVertical = styled.div`
  background-color: #000;
  display: flex;
  width: ${mobileVW(5)};
  height: ${mobileVW(5)};
  border-radius: ${mobileVW(5)};
  margin-left: ${mobileVW(-2)};
  ${desktopBreakpoint} {
    width: ${desktopVW(5)};
    height: ${desktopVW(5)};
    border-radius: ${desktopVW(5)};
    margin-left: ${desktopVW(-2)};
  }
`;

const ContentHorizontal = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: ${desktopVW(-2)};
`;

const CircleHorizontal = styled.div`
  width: ${mobileVW(5)};
  height: ${mobileVW(5)};
  border-radius: ${mobileVW(5)};
  margin-top: -${mobileVW(1)};
  background-color: #000;
  display: flex;
  ${desktopBreakpoint} {
    width: ${desktopVW(5)};
    height: ${desktopVW(5)};
    border-radius: ${desktopVW(5)};
    margin-top: 0;
  }
`;

const TitleVertical = styled.div`
  font-size: ${mobileVW(10)};
  margin-top: ${mobileVW(35)};
  transform: rotate(270deg);
  margin-left: ${mobileVW(14)};
  white-space: nowrap;
  font-weight: 700;
  ${desktopBreakpoint} {
    font-size: ${desktopVW(12)};
    margin-top: ${desktopVW(16)};
    margin-left: ${desktopVW(20)};
    margin-top: ${desktopVW(20)};
  }
`;

const TitleHorizontal = styled.div`
  font-size: ${mobileVW(10)};
  margin-top: ${mobileVW(10)};
  white-space: nowrap;
  font-weight: 700;
  ${desktopBreakpoint} {
    font-size: ${desktopVW(12)};
    margin-top: ${desktopVW(20)};
  }
`;
